import {
    createRouter,
    createWebHistory,
    type RouteRecordRaw,
} from "vue-router";
import { useAuth } from "carmine-auth-vue";
import { Routes } from "~/router/map"
import { useAbility } from "@casl/vue";
import { filterNavlinksByAbility } from '~/router/config'
import { useApolloClient } from '@vue/apollo-composable';
import { GetAclModulePermissionsForUserIdDocument, type GetAclModulePermissionsForUserIdQuery } from '~/graphql/types';
import type { SubjectRawRule, ExtractSubjectType, Subject, MongoQuery } from '@casl/ability'
import type { NavigationObject } from "carmine-ui/components";
import { SettingsTabsQuery, settingsModuleNames } from "~/helpers/settings";

const routes: RouteRecordRaw[] = [
    {
        name: "home",
        path: "/",
        component: () => import("~/pages/home.vue"),
        meta: { requiresAuth: true },
        children: [
            {
                name: "settings",
                path: "settings",
                component: () => import("~/pages/settings.vue"),
                redirect: "/settings/profile",
                children: [
                    {
                        name: "profile",
                        path: "profile",
                        component: () => import("~/pages/settings/my-profile.vue"),
                    }, {
                        name: "notifications",
                        path: "notifications",
                        component: () => import("~/pages/settings/notifications.vue"),
                    }, {
                        name: "branch-management",
                        path: SettingsTabsQuery.Branches,
                        component: () => import("~/pages/settings/manage-branches.vue"),
                        meta: {
                            moduleName: settingsModuleNames[SettingsTabsQuery.Branches]
                        }
                    }, {
                        name: "test-drive-timeslots",
                        path: SettingsTabsQuery.TestDriveSlots,
                        component: () => import("~/pages/settings/manage-test-drive-slots.vue"),
                        meta: {
                            moduleName: settingsModuleNames[SettingsTabsQuery.TestDriveSlots]
                        }
                    }, {
                        name: "inspection-timeslots",
                        path: SettingsTabsQuery.InspectionSlots,
                        component: () => import("~/pages/settings/manage-inspection-slots.vue"),
                        meta: {
                            moduleName: settingsModuleNames[SettingsTabsQuery.InspectionSlots]
                        }
                    }, {
                        name: "vehicle-model-management",
                        path: SettingsTabsQuery.Models,
                        component: () => import("~/pages/settings/vehicle-model.vue"),
                        meta: {
                            moduleName: settingsModuleNames[SettingsTabsQuery.Models]
                        }
                    }, {
                        name: "bidding-schedule-management",
                        path: SettingsTabsQuery.BiddingSchedules,
                        component: () => import("~/modules/bidding-sessions/pages/BiddingPage.vue"),
                        meta: {
                            moduleName: settingsModuleNames[SettingsTabsQuery.BiddingSchedules]
                        }
                    }, {
                        name: "voucher-management",
                        path: SettingsTabsQuery.Vouchers,
                        component: () => import("~/modules/vouchers/pages/VoucherPage.vue"),
                        meta: {
                            moduleName: settingsModuleNames[SettingsTabsQuery.Vouchers]
                        }
                    }
                ]
            },
            {
                name: "dashboard",
                path: "dashboard",
                component: () => import("~/pages/management/dashboard.vue"),
                meta: {
                    moduleName: "Dashboard"
                },
            },
            {
                name: "manage-orders",
                path: "manage/orders",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Order Management"
                },
                children: [
                    {
                        name: "manage-order-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import("~/pages/management/orders/orders-list.vue"),
                    },
                    {
                        name: "manage-order-view",
                        path: ":id",
                        component: () =>
                            import("~/pages/management/orders/orders-view.vue"),
                    },
                    {
                        name: "manage-order-add",
                        path: "add",
                        meta: {
                            caslAction: "create"
                        },
                        component: () =>
                            import("~/pages/management/orders/orders-add.vue"),
                    },
                    {
                        name: "manage-order-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import("~/pages/management/orders/orders-edit.vue"),
                    },
                ],
            },
            {
                name: "manage-inspections",
                path: "manage/inspections",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Inspection Management"
                },
                children: [
                    {
                        name: "manage-inspection-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/pages/management/inspection-reports/inspection-list.vue"
                            ),
                    },
                    {
                        name: "manage-inspection-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/pages/management/inspection-reports/inspection-view.vue"
                            ),
                        props: (route) => ({
                            id: route.params.id as string,
                            versionId: route.query.versionId as string,
                        }),
                    },
                    {
                        name: "manage-inspection-add",
                        path: "add",
                        meta: {
                            caslAction: "create"
                        },
                        component: () =>
                            import(
                                "~/pages/management/inspection-reports/inspection-add.vue"
                            ),
                    },
                    {
                        name: "manage-inspection-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import(
                                "~/pages/management/inspection-reports/inspection-edit.vue"
                            ),
                        props: (route) => ({
                            id: route.params.id as string,
                            redirect: route.query.redirect as string,
                        }),
                    },
                ],
            },
            {
                name: "manage-test-drives",
                path: "manage/test-drives",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Test Drive Management"
                },
                children: [
                    {
                        name: "manage-test-drives-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/pages/management/test-drives/test-drive-list.vue"
                            ),
                    },
                    {
                        name: "manage-test-drives-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/pages/management/test-drives/test-drive-view.vue"
                            ),
                    },
                    {
                        name: "manage-test-drives-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import(
                                "~/pages/management/test-drives/test-drive-edit.vue"
                            ),
                    },
                ],
            },
            {
                name: "manage-inspection-booking",
                path: "manage/inspection-booking",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Inspection Booking"
                },
                children: [
                    {
                        name: "manage-inspection-booking-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/pages/management/inspection-bookings/inspection-booking-list.vue"
                            ),
                    },
                    {
                        name: "manage-inspection-booking-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/pages/management/inspection-bookings/inspection-booking-view.vue"
                            ),
                    },
                    {
                        name: "manage-inspection-booking-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import(
                                "~/pages/management/inspection-bookings/inspection-booking-edit.vue"
                            ),
                    },
                ],
            },
            {
                name: "booking-calendar",
                path: "booking-calendar",
                component: () =>
                    import("~/pages/management/booking-calendar.vue"),
            },
            {
                name: "manage-vehicles",
                path: "manage/vehicles",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Retail Management",
                },
                children: [
                    {
                        name: "manage-vehicles-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/modules/vehicles/pages/VehicleManagementListPage.vue"
                            ),
                    },
                    {
                        name: "manage-vehicles-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/modules/vehicles/pages/VehicleViewPage.vue"
                            ),
                    },
                    {
                        name: "manage-vehicles-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import(
                                "~/modules/vehicles/pages/VehicleEditPage.vue"
                            ),
                    },
                    {
                        name: "manage-vehicles-add",
                        path: "add",
                        meta: {
                            caslAction: "create"
                        },
                        component: () =>
                            import(
                                "~/modules/vehicles/pages/VehicleAddPage.vue"
                            ),
                    },
                    {
                        name: "manage-vehicles-booking",
                        path: ":id/booking",
                        component: () =>
                            import(
                                "~/modules/vehicles/pages/VehicleBookingPage.vue"
                            ),
                    },
                ],
            },
            {
                name: "manage-biddings",
                path: "manage/biddings",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Bidding Management"
                },
                children: [
                    {
                        name: "manage-biddings-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/modules/biddings/pages/BiddingManagementPage.vue"
                            ),
                    },
                    {
                        name: "manage-biddings-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/modules/biddings/pages/BiddingViewPage.vue"
                            ),
                    },
                    {
                        name: "manage-biddings-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import(
                                "~/modules/biddings/pages/BiddingEditPage.vue"
                            ),
                    },
                    {
                        name: "manage-biddings-add",
                        path: "add",
                        meta: {
                            caslAction: "create"
                        },
                        component: () =>
                            import(
                                "~/modules/biddings/pages/BiddingAddPage.vue"
                            ),
                        props(to) {
                            return {
                                from: to.query.from as string,
                            };
                        },
                    },
                    {
                        name: "manage-biddings-result",
                        path: ":id/result",
                        component: () =>
                            import(
                                "~/modules/biddings/pages/BiddingResultPage.vue"
                            ),
                    },
                    {
                        name: "manage-biddings-sessions",
                        path: ":id/session",
                        component: () =>
                            import(
                                "~/modules/biddings/pages/BiddingSessionPage.vue"
                            )
                    },
                    {
                        name: "manage-biddings-payment",
                        path: ":id/payment",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/modules/biddings/pages/PaymentDetailsPage.vue"
                            )
                    }
                ],
            },
            {
                name: "audit-trail",
                path: "audit-trail",
                meta: {
                    moduleName: "Audit Trail"
                },
                component: () => import("~/pages/management/audit-trail.vue"),
            },
            {
                name: "manage-users",
                path: "manage/users",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "User Management"
                },
                children: [
                    {
                        name: "manage-users-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import("~/pages/management/users/user-list.vue"),
                    },
                    {
                        name: "manage-users-view",
                        path: ":id",
                        component: () =>
                            import("~/pages/management/users/user-view.vue"),
                    },
                    {
                        name: "manage-users-edit",
                        meta: {
                            caslAction: "update"
                        },
                        path: ":id/edit",
                        component: () =>
                            import("~/pages/management/users/user-edit.vue"),
                    },
                ],
            },
            {
                name: "manage-roles",
                path: "manage/roles",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Role Management"
                },
                children: [
                    {
                        name: "manage-roles-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import("~/pages/management/roles/role-list.vue"),
                    },
                    {
                        name: "manage-roles-available",
                        path: "available",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import("~/pages/management/roles/role-available.vue"),
                    },
                    {
                        name: "manage-roles-view",
                        path: ":id",
                        component: () =>
                            import("~/pages/management/roles/role-view.vue"),
                    },
                    {
                        name: "manage-roles-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import("~/pages/management/roles/role-edit.vue"),
                    },
                ],
            },
            {
                name: "manage-customers",
                path: "manage/customers",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Customer Management"
                },
                children: [
                    {
                        name: "manage-customers-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/pages/management/customers/customers-list.vue"
                            ),
                    },
                    {
                        name: "manage-customers-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/pages/management/customers/customers-view.vue"
                            ),
                    },
                    {
                        name: "manage-customers-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import(
                                "~/pages/management/customers/customers-edit.vue"
                            ),
                    },
                ],
            },
            {
                name: "manage-contact-us",
                path: "manage/contact-us",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Contact Us Management"
                },
                children: [
                    {
                        name: "manage-contact-us-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/pages/management/contact-us/contact-us-list.vue"
                            ),
                    },
                    {
                        name: "manage-contact-us-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/pages/management/contact-us/contact-us-view.vue"
                            ),
                    },
                ],
            },
            {
                name: "manage-brokers",
                path: "manage/brokers",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Broker Management"
                },
                children: [
                    {
                        name: "manage-broker-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/pages/management/brokers/broker-list.vue"
                            ),
                    },
                    {
                        name: "manage-broker-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/pages/management/brokers/broker-view.vue"
                            ),
                    },
                    {
                        name: "manage-broker-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import(
                                "~/pages/management/brokers/broker-edit.vue"
                            ),
                    },
                ],
            },
            {
                name: "manage-dealers",
                path: "manage/dealers",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Dealer Management"
                },
                children: [
                    {
                        name: "manage-dealer-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/pages/management/dealers/dealer-list.vue"
                            ),
                    },
                    {
                        name: "manage-dealer-view",
                        path: ":id",
                        component: () =>
                            import(
                                "~/pages/management/dealers/dealer-view.vue"
                            ),
                    },
                    {
                        name: "manage-dealer-edit",
                        path: ":id/edit",
                        meta: {
                            caslAction: "update"
                        },
                        component: () =>
                            import(
                                "~/pages/management/dealers/dealer-edit.vue"
                            ),
                    },
                ],
            },
            {
                name: "manage-recommendations",
                path: "manage/recommendations",
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                meta: {
                    moduleName: "Vehicle Recommendations"
                },
                children: [
                    {
                        name: "manage-recommendations-list",
                        path: "",
                        meta: {
                            caslAction: "select"
                        },
                        component: () =>
                            import(
                                "~/pages/management/recommendations/recommendations-list.vue"
                            ),
                    },
                ],
            },
        ],
        redirect: "/dashboard",
    },
    {
        name: "inspection-report",
        path: "/manage/inspections/:id/report",
        component: () =>
            import("~/pages/management/inspection-reports/report-view.vue"),
        props: (route) => ({
            type: route.query.type,
            id: route.params.id as string,
            versionId: route.query.versionId as string,
        }),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: "login",
        path: Routes.Login,
        component: () => import("~/modules/authentication/pages/LoginPage.vue"),
        meta: { noAuthOnly: true },
    },
    {
        name: "forget-password",
        path: Routes.ForgotPassword,
        component: () => import("~/modules/authentication/pages/ForgotPage.vue"),
        meta: { noAuthOnly: true },
    },
    {
        name: "sign-up",
        path: Routes.SignUp,
        component: () => import("~/modules/authentication/pages/SignUpPage.vue"),
        meta: { noAuthOnly: true },
    },
    {
        name: "verify-otp",
        path: Routes.VerifyOtp,
        component: () => import("~/modules/authentication/pages/VerifyOtpPage.vue"),
        meta: { noAuthOnly: true },
    },
    {
        name: "reset-password",
        path: `${Routes.ResetPassword}/:payload`,
        component: () => import("~/modules/authentication/pages/ResetPage.vue"),
    },
    {
        name: "change-password",
        path: "/change-password",
        component: () => import("~/pages/settings/change-password.vue"),
        meta: { requiresAuth: true },
    },
    {
        name: "not-found",
        path: "/:path(.*)*",
        redirect: "/",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.afterEach(() => {
    // Scrolls main container to top
    document
        .getElementById("main-container")
        ?.scrollTo({ top: 0, behavior: "smooth" });
});

router.beforeEach(async (to, from) => {
    const auth = useAuth();
    const token = useToken()
    const ability = useAbility();
    const { client } = useApolloClient()

    if (to.meta.noAuthOnly && auth.isAuthenticated.value) {
        return {
            path: "/",
        };
    }

    if (to.meta.requiresAuth && !auth.isAuthenticated.value) {
        const isValid = await token.checkTokenValidity()
        if (!isValid) {
            await auth.logout()
            return {
                path: '/login'
            }
        }
    }

    // Fetch module permissions for currently logged-in (isAuthenticated) user
    if (auth.isAuthenticated.value) {
        const { data } = await client.query<GetAclModulePermissionsForUserIdQuery>({
            query: GetAclModulePermissionsForUserIdDocument,
            variables: {
                id: auth.hasuraUser.value.id
            }
        })
        const newPermissions = data;
        // Update abilities
        if (newPermissions && newPermissions.user) {
            if (!newPermissions.user.aclRole.backendOnly) {
                // Rule JSON
                const rules: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>[] = []
                const auth0Id = auth.hasuraUser.value.auth0Id
                const modulePermissions = newPermissions.user.aclRole.modulePermissions

                for (const permission of modulePermissions) {
                    if (permission.module) {
                        const hasFullAccess = permission.createPermission && permission.readPermission && permission.updatePermission && permission.deletePermission
                        if (hasFullAccess) {
                            rules.push({
                                action: 'manage',
                                subject: permission.module.name
                            })
                        } else {
                            if (permission.createPermission) {
                                const createRule: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery> = {
                                    subject: permission.module.name,
                                    action: 'create'
                                }
                                rules.push(createRule)
                            }
                            if (permission.readPermission || permission.readOwnPermission) {
                                const selectRule: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery> = {
                                    subject: permission.module.name,
                                    action: 'select',
                                    ...(permission.readOwnPermission && !permission.readPermission) && {
                                        conditions: {
                                            "createdBy.auth0Id": auth0Id
                                        },
                                        graphQLFilter: {
                                            createdBy: {
                                                auth0Id: {
                                                    _eq: auth0Id
                                                }
                                            }
                                        }
                                    }
                                }
                                rules.push(selectRule)
                            }
                            if (permission.updatePermission || permission.updateOwnPermission) {
                                const updateRule: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery> = {
                                    subject: permission.module.name,
                                    action: 'update',
                                    ...(permission.updateOwnPermission && !permission.updatePermission) && {
                                        conditions: {
                                            "createdBy.auth0Id": auth0Id
                                        }
                                    }
                                }
                                rules.push(updateRule)
                            }
                            if (permission.deletePermission || permission.deleteOwnPermission) {
                                const deleteRule: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery> = {
                                    subject: permission.module.name,
                                    action: 'delete',
                                    ...(permission.deleteOwnPermission && !permission.deletePermission) && {
                                        conditions: {
                                            "createdBy.auth0Id": auth0Id
                                        }
                                    }
                                }
                                rules.push(deleteRule)
                            }
                        }
                    }
                }
                ability.update(rules)
            } else {
                ability.update([{
                    action: 'manage',
                    subject: 'all'
                }])
            }
        }
    }
    // Only check for permission if there is a moduleName under meta
    if (to.meta.moduleName) {
        if (!(ability.can('select', to.meta.moduleName as string) || ability.can('create', to.meta.moduleName as string))) {
            const navLink = filterNavlinksByAbility(ability);
            return {
                path: getFirstPath(navLink)
            }
        }
    }
    return true;
});

/**
 * Recursively searches through an array of navigation link objects to find
 * the first navigable path. A navigable path is considered to be a non-empty,
 * non-placeholder string. If no navigable paths are found, it returns a root path ('/').
 *
 * @param {Array<NavigationObject>} arr - An array of objects representing navigation links,
 * which may contain nested children arrays of links.
 * @returns {string} The path string of the first navigable link found, or '/' if none are navigable.
 */
function getFirstPath(
    arr: Array<NavigationObject>,
): string {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].children) {
            let path = getFirstPath(arr[i].children!);
            if (path) return path;
        }
        if (arr[i].path && arr[i].path !== '#') {
            return arr[i].path!;
        }
    }
    // If user do not have access to any modules, redirect to settings page by default
    return '/settings'
}

export default router;
